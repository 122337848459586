import React from 'react'
import { graphql } from 'gatsby'
import { HomePageTemplate } from 'precision/pages/home' //TODO:create index, webpack alias, etc to clean up. also rename components to lib and precision to components

const IndexPage = ({ data }) => {
  const {
    indexJson,
    file: { childImageSharp: image },
  } = data
  return <HomePageTemplate {...indexJson} image={image} />
}
export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage {
    indexJson {
      slogan1
      slogan2
      slogan3
    }
    file(relativePath: { eq: "img/sprinkler.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
