import React from 'react'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import { themeGet } from 'styled-system'
import styled from '@emotion/styled'
import { EstimateForm } from './'
// import * as Icon from 'react-feather'
// import { Button } from '@roo-ui/components'

import {
  BackgroundImage,
  Flex,
  Heading,
  Text,
  OutlineButton,
  Label,
  Icon,
  Input,
  Card,
  Container,
} from 'components'

Icon.isIcon = true

//TODO:OutlineButton color is not passing down
const QuoteForm = () => (
  <form
    name="estimate"
    method="POST"
    data-netlify="true"
    action="/success?no-cache=1"
    data-netlify-honeypot="social"
  >
    <Label htmlFor="name" textStyle="labelAlt">
      Name
    </Label>
    {/* <Icon.User size="20" /> */}
    <input type="hidden" name="social" />
    <Input id="name" name="name" />

    <Label htmlFor="phone" textStyle="labelAlt">
      Phone Number
    </Label>
    {/* <Icon.Phone size="20" /> */}
    <Input id="phone" name="phone" />

    <Label htmlFor="address" textStyle="labelAlt">
      Street Address
    </Label>
    {/* <Icon.MapPin size="20" /> */}
    <Input id="address" name="address" />

    <Label htmlFor="zip" textStyle="labelAlt">
      Zip Code
    </Label>
    {/* <Icon.MapPin size="20" /> */}
    <Input id="zip" name="zip" />
    <OutlineButton
      variant="default"
      color="white"
      style={{ color: 'white' }}
      type="submit"
    >
      Get your free estimate now!
    </OutlineButton>
  </form>
)

//TODO: post on github asking for if there is a better way to do this
const QuoteFormContainer = styled(Flex)(props => ({
  background: transparentize(0.25, themeGet('colors.brand.primary')(props)),
}))

const ImageCard = styled(Card)`
  position: relative;
  &:after {
    content: 'Learn More';
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    opacity: 0;
    background: ${props => themeGet('colors.brand.secondary')(props)};
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
  }
  &:hover:after {
    opacity: 1;
  }
`
ImageCard.defaultProps = {
  bg: 'white',
  shadow: 'small',
  width: '20em',
  hAlignContent: 'center',
  vAlignContent: 'center',
  col: 'true',
  height: '15em',
}

//TODO:flex set wrap=true by default and make noWrap a shortcut
export const HomePageTemplate = ({
  slogan1,
  slogan2,
  slogan3,
  image,
  ...rest
}) => {
  return (
    <>
      <BackgroundImage
        display="flex"
        justifyContent="stretch"
        alignItems="stretch"
        flex="1 1 auto"
        fluid={image.fluid}
        minHeight={600}
        flexWrap="wrap"
        backgroundPosition={['left', 'center']}
      >
        <Flex grow vAlignContent="center" hAlignContent="center" m={24}>
          <QuoteFormContainer
            p={24}
            bg="rgba(1,1,1,.5)"
            color="white"
            borderRadius="default"
          >
            <Heading mb={0} textAlign="center">
              Expert sprinkler systems since 1986
            </Heading>
          </QuoteFormContainer>
        </Flex>
        <QuoteFormContainer
          col
          width={['100%', 1 / 3]}
          p={[2, 4]}
          color="white"
          centered
        >
          <Heading textAlign="center">Get a Free Estimate</Heading>
          <EstimateForm />
          {/* <QuoteForm /> */}
        </QuoteFormContainer>
      </BackgroundImage>
      <Flex
        bg="brand.secondary"
        col
        color="white"
        hAlignContent="center"
        p={16}
      >
        <Heading.h1>How Can We Help You?</Heading.h1>
        <Text textAlign="center">
          Find out how our team of experts can craft you an irrigation system
          tailored to meet your goals.
        </Text>
        <OutlineButton>Let's Get in Touch</OutlineButton>
      </Flex>
      <Flex hAlignContent="space-around" bg="offWhite" py={4} wrap>
        <Link to="/services">
          <ImageCard m={16}>
            <Icon name="invertColors" size={80} />
            <Heading textAlign="center">Irrigation</Heading>
          </ImageCard>
        </Link>
        <Link to="/services">
          <ImageCard m={16}>
            <Icon name="spa" size={80} />
            <Heading textAlign="center">Sod</Heading>
          </ImageCard>
        </Link>
        <Link to="/services">
          <ImageCard to="/services">
            <ImageCard m={16}>
              <Icon name="brightnessLow" size={80} />
              <Heading textAlign="center">Landscape Lighting</Heading>
            </ImageCard>
          </ImageCard>
        </Link>
      </Flex>
    </>
  )
}

export default HomePageTemplate
