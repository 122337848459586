import React from 'react'
import { Form, asField } from 'informed'

import { Flex, Text, Icon, Label, Input, Spacer } from 'components'
import { navigate } from 'gatsby' //prob move outside of here?

const handleSubmit = data => {
  if (!data || data.social) {
    return
  }
  fetch('/.netlify/functions/sendEmail', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(response => {
    //TODO: check and handle error
    navigate('/success')
  })
}

const FormInput = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props
  return (
    <Input
      {...rest}
      ref={forwardedRef}
      value={!value && value !== 0 ? '' : value}
      onChange={e => {
        setValue(e.target.value)
        if (onChange) {
          onChange(e)
        }
      }}
      onBlur={e => {
        setTouched(true)
        if (onBlur) {
          onBlur(e)
        }
      }}
    />
  )
})

const EstimateForm = () => (
  <Flex
    onSubmit={handleSubmit}
    name="estimate"
    as={Form}
    col
    p={16}
    width={['100%', '50%']}
    grow
    width="100%"
  >
    <FormInput type="hidden" name="social" field="social" isHoneypot />

    <Label htmlFor="name" textStyle="labelAlt">
      Name
    </Label>
    <FormInput field="name" required />
    <Label textStyle="labelAlt">Phone Number</Label>
    <FormInput field="phone" required type="phone" />
    <Label textStyle="labelAlt">Street Address</Label>
    <FormInput field="address" required />
    <Label textStyle="labelAlt">Zip Code</Label>
    <FormInput field="zip" required />
    <Spacer />

    <Flex
      as="button"
      type="submit"
      display="button"
      bg="transparent"
      p={16}
      hAlignContent="center"
      vAlignContent="center"
      borderColor="white"
      borderStyle="solid"
    >
      <Text
        color="white"
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="lg"
      >
        Get your free estimate now!
      </Text>
    </Flex>
  </Flex>
)
export default EstimateForm
